/**
 * @Description 定义本地路由文件
 * @author 滕威
 * @time 2019-12-02
 */
// 公共路由
export const constantRouterMap = [
  // 登录
  {
    path: "/",
    name: "login",
    component: () => import("../views/sys/login/index.vue")
  },
  // 开标大厅登录
  {
    path: "/login",
    name: "logins",
    component: () => import("../views/sys/logins/index.vue")
  },
  // 开标大厅一览
  {
    path: "/openHall",
    name: "openHall",
    component: () => import("../views/page/openHall/index.vue")
  },
  // 开标大厅详情
  {
    path: "/openHallDetails",
    name: "openHallDetails",
    component: () => import("../views/page/openHall/details/index.vue")
  },
  // 企业信息查询
  {
    path: "/enterInfoSearch",
    name: "enterInfoSearch",
    component: () => import("../views/page/enterInfoSearch/index.vue")
  },
  // 交易中心
  {
    path: "/tradingCenter",
    name: "tradingCenter",
    component: () => import("../views/page/tradingCenter/index.vue")
  },
  // 项目一览
  {
    path: "/tradingCenterList",
    name: "tradingCenterList",
    component: () =>
      import(
        "../views/page/tradingCenter/components/tradingCenterList/index.vue"
      )
  },
  // 项目详情
  {
    path: "/tradingCenterDetail",
    name: "tradingCenterDetail",
    component: () =>
      import(
        "../views/page/tradingCenter/components/tradingCenterDetail/index.vue"
      )
  },
  // web交易中心
  {
    path: "/webTradingCenter",
    name: "webTradingCenter",
    component: () => import("../views/page/webTradingCenter/index.vue")
  }
];
