import axios from "@/axios";

/**
 * get请求
 * @author zhouy
 * @param {*} url 请求路径
 * @param {*} parameter 请求参数key=value&key1=value1
 */
export function fun_GetAction(url, parameter) {
  return axios({
    url: url,
    method: "get",
    params: parameter
  });
}
/*
 * blob get请求
 */
export function fun_GetBlob(url, parameter) {
  return axios({
    url: url,
    method: "get",
    params: parameter,
    timeout: "auto",
    responseType: "blob"
  });
}

/**
 * post请求
 * @author zhouy
 * @param {*} url 请求路径
 * @param {*} parameter 请求参数{key:value,key1:value1}
 */
export function fun_PostAction(url, data) {
  return axios({
    url: url,
    method: "post",
    data
  });
}

/**
 * put请求, 修改方法
 * @author zhouy
 * @param {*} url 请求路径
 * @param {*} parameter 请求参数{key:value,key1:value1}
 */
export function fun_PutAction(url, parameter) {
  return axios({
    url: url,
    method: "put",
    data: parameter
  });
}

/**
 * delete请求, 删除方法
 * @author zhouy
 * @param {*} url 请求路径
 * @param {*} parameter 请求参数keys=1,2,3
 */
export function fun_DeleteAction(url, parameter) {
  return axios({
    url: url,
    method: "delete",
    data: parameter
  });
}

/**
 * @Description 上传
 * @author 滕威
 * @params data  入参对象
 * @time 2019-12-02
 */
export const fun_UploadImg = (url, data, time) => {
  return axios({
    url,
    async:false,
    method: "post",
    timeout: time,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  });
};

/**
 * @Description 下载
 * @author zhouy
 * @params data  入参对象
 * @time 2019-12-02
 */
export const fun_Download = (url, data) => {
  return axios({
    url,
    responseType: "blob",
    timeout: 0,
    method: "post",
    data
  });
};

/**
 * @Description 字典
 * @author 滕威
 * @params sqlId、dictionaryId、typNum、certTypeNum、tradeLargeClass  入参字段
 * @time 2019-12-02
 */
export function fun_GetDic(url, params) {
  return axios({
    url,
    method: "post",
    params
  });
}

/**
 * @Description 验证码
 * @author 滕威
 * @params url  请求url
 * @params params  参数
 * @time 2021-01-14
 */
export const fun_GetImgCode = (url, params) => {
  return axios({
    url: `${url}`,
    responseType: "arraybuffer", // 类型
    method: "get",
    params
  });
};

/**
 * @Description 查询企业信息
 * @author 滕威
 * @params url  请求url
 * @params params  参数
 * @time 2021-03-12
 */
export const fun_GetEnterInfo = (url, params) => {
  return axios({
    url: `${url}`,
    method: "get",
    headers: { Authorization: "12881b11-aa65-4c80-a75c-05343cd419b7" },
    params
  });
};
