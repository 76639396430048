/**
 * @Description 操作动态路由文件
 * @author 滕威
 * @time 2019-12-02
 */
import router from "../router/router";
import { fun_GetAction } from "@/api";
import store from "../store";
// const _import = require("../router/_import_" + process.env.NODE_ENV); //获取组件的方法
import Layout from "../views/layout/Home"; //Layout 是架构组件，不在后台返回，在文件里单独引入

var getRouter; //用来获取后台拿到的路由
var otherRouters = []; // 用来存储详情路由
router.beforeEach((to, from, next) => {
  // 全局进行错误拦截
  // 解决路由抛出的异常Uncaught (in promise) undefined
  // 主要是因为router3.1.0+在使用this.$router.push时返回了promise，需要特殊处理，否则加载router时会自动抛出异常
  const routerMethods = ["push", "replace"];
  routerMethods.forEach(method => {
    const originalCall = router[method];
    router[method] = function(location, onResolve, onReject) {
      if (onResolve || onReject) {
        return originalCall.call(this, location, onResolve, onReject);
      }
      return originalCall.call(this, location).catch(err => err);
    };
  });
  if (to.name == "login") {
    sessionStorage.clear();
    getRouter = false;
  }
  if (!getRouter && sessionStorage.getItem("TB__TOKEN")) {
    //不加这个判断，路由会陷入死循环
    if (!sessionStorage.getItem("router")) {
      fun_GetAction("/menu").then(res => {
        getRouter = res.data.data.router; //假装模拟后台请求得到的路由数据
        const userInfo = {
          realName: res.data.data.realName,
          username: res.data.data.username
        };
        store.commit("SET_USERINFO", userInfo); // 存储路由到vuex
        store.commit("SET_ROUTER", getRouter); // 存储路由到vuex
        routerGo(to, next, getRouter); //执行路由跳转方法
      });
    } else {
      //从sessionStorage拿到了路由
      getRouter = JSON.parse(sessionStorage.getItem("router"));
      routerGo(to, next, getRouter);
    }
  } else {
    next();
  }
});

function routerGo(to, next, routerJson) {
  filterOtherRouter(routerJson);
  var asyncRouters = filterAsyncRouter(routerJson);
  const errorRouter = [
    {
      path: "/404",
      name: "exception404",
      component: () => import("../components/exception/404")
    },
    {
      path: "/403",
      name: "exception403",
      component: () => import("../components/exception/403")
    },
    {
      path: "/500",
      name: "exception500",
      component: () => import("../components/exception/500")
    }
  ];
  const homeRouter = [
    {
      path: "/home",
      name: "home",
      component: () => import("../views/layout/Home"),
      redirect: { path: "/userManage" },
      children: [...otherRouters, ...errorRouter]
    }
  ];
  router.addRoutes(homeRouter); //动态添加路由
  router.addRoutes(asyncRouters); //动态添加路由
  global.antRouter = asyncRouters; //将路由数据传递给全局变量，做侧边栏菜单渲染工作
  next({
    ...to,
    replace: true
  });
}

// function saveObjArr(name, data) {
//   //sessionStorage 存储数组对象的方法
//   sessionStorage.setItem(name, JSON.stringify(data));
// }

// function getObjArr(name) {
//   //sessionStorage 获取数组对象的方法
//   return JSON.parse(window.sessionStorage.getItem(name));
// }

function filterAsyncRouter(asyncRouterMap) {
  //遍历后台传来的路由字符串，转换为组件对象
  const accessedRouters = asyncRouterMap.filter(route => {
    if (route.component) {
      if (route.component === "Layout") {
        //Layout组件特殊处理
        route.component = Layout;
      } else {
        route.component = componentImport(route.component);
      }
    }
    if (route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
  return accessedRouters;
}

function filterOtherRouter(asyncRouterMap) {
  for (var i in asyncRouterMap) {
    let otherRouter = {};
    if (asyncRouterMap[i].component) {
      if (asyncRouterMap[i].component === "Layout") {
        //Layout组件特殊处理
      } else {
        otherRouter.name = asyncRouterMap[i].name.replace(
          asyncRouterMap[i].name,
          asyncRouterMap[i].name + "Detail"
        );
        otherRouter.path = asyncRouterMap[i].path.replace(
          asyncRouterMap[i].path,
          asyncRouterMap[i].path + "Detail"
        );
        otherRouter.meta = {};
        if (asyncRouterMap[i].title != "") {
          otherRouter.title = asyncRouterMap[i].title + "详情";
          otherRouter.component = componentImport(
            asyncRouterMap[i].component.replace(
              asyncRouterMap[i].path,
              asyncRouterMap[i].path + "/details"
            )
          );
        }
      }
    }
    if (otherRouter.name) {
      otherRouters.push(otherRouter);
    }
    if (asyncRouterMap[i].children && asyncRouterMap[i].children.length) {
      asyncRouterMap[i].children = filterOtherRouter(
        asyncRouterMap[i].children
      );
    }
  }
  return asyncRouterMap;
}

function componentImport(component) {
  return () => import("../views/" + component);
}
