/**
 * @Description 全局路由文件，导出路由
 * @author 滕威
 * @time 2019-12-02
 */
import Vue from "vue";
import VueRouter from "vue-router";
import { constantRouterMap } from "./index.js";

Vue.use(VueRouter);

export default new VueRouter({ routes: constantRouterMap });
