import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import "./styles/export.scss";
import "./assets/font/font.css";
import "./utils/index";
import "element-ui/lib/theme-chalk/index.css";
import "@/utils/promission.js";
import Co_component from "./components/index";
import "./utils/rem"; // 适配

Vue.use(Co_component);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
