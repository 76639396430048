<!--
 * 侧边栏导航
 * @author 滕威
 * @time 2021-01-08
 *
-->
<!-- 模板(html)代码 start -->
<template>
  <div class="layout-aside">
    <el-menu
      router
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @select="fun_HandleSelect"
      text-color="rgba(255,255,255,.7)"
      active-text-color="#FFFFFF"
    >
      <!-- 一级菜单 -->
      <div v-for="(item, index) in asyncRouters" :key="index">
        <el-submenu
          class="menuOne"
          v-if="item.children && item.children.length > 0"
          :index="item.icon"
        >
          <div slot="title">
            <i :class="'el-icon-' + item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </div>
          <!-- 二级菜单 -->
          <template v-for="itemChild in item.children">
            <el-submenu
              class="menuTwo"
              v-if="itemChild.children && itemChild.children.length"
              :index="itemChild.path"
              :key="itemChild.path"
            >
              <div slot="title">
                <i :class="'el-icon-' + itemChild.icon"></i>
                <span slot="title">{{ itemChild.title }}</span>
              </div>
              <!-- 三级菜单 -->
              <template v-for="itemChild_Child in itemChild.children">
                <el-menu-item
                  class
                  :index="itemChild_Child.path"
                  :key="itemChild_Child.path"
                >
                  <div slot="title">
                    <span>{{ itemChild_Child.title }}</span>
                  </div>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item v-else :index="itemChild.path" :key="itemChild.path">
              <i :class="'el-icon-' + itemChild.icon" v-if="itemChild.icon"></i>
              <span slot="title">{{ itemChild.title }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item v-else :index="item.path" :key="item.path">
          <i :class="'el-icon-' + item.icon"></i>
          <span slot="title">{{ item.title }}</span></el-menu-item
        >
      </div>
    </el-menu>
  </div>
</template>
<!-- 模板(html)代码 end -->

<!-- JS代码 start -->
<script>
import { mapState } from "vuex";
/*
 * VUE组件默认导出属性
 * @author 滕威
 */
export default {
  /**
   * 组件默认导出属性
   * @author 滕威
   */

  // 初始化数据对象
  data() {
    return {
      // routerList: [], //侧边栏数据
      activePath: "" // 选中的菜单
    };
  },

  // 计算属性
  computed: {
    ...mapState(["asyncRouters"])
  },

  // 模板编译完成
  mounted() {
    // 页面初始化
    this.fun_Init();
  },

  // 自定义事件
  methods: {
    /**
     * 页面初始化
     * @author 滕威
     */
    fun_Init() {
      // 获取选中path
      this.activePath = this.$route.path;
      // let routers = this.asyncRouters[0].children.filter(item => item);
      // // 将首页渲染到侧边栏
      // this.routerList = [...routers];
    },
    /**
     * 选择菜单执行
     * @params key 选中菜单path
     * @author 滕威
     */
    fun_HandleSelect(key) {
      // 将选中的菜单path赋值
      this.activePath = key;
    }
  }
};
</script>
<!-- JS代码 end -->

<!-- css样式 start-->
<style lang="scss" scoped>
.layout-aside {
  /deep/.el-submenu__title {
    height: 44px;
    line-height: 44px;
    text-align: left;
    padding: 0 20px 0 14px !important;
    background-color: #3c4348 !important;
    color: #d0dce8 !important;
    > div:first-of-type {
      > i {
        color: #d0dce8;
      }
    }
    > i {
      color: #d0dce8;
    }
  }
  .el-menu {
    border-right: none;
    .el-menu-item {
      height: 44px;
      line-height: 44px;
      padding: 0px 16px 0 39px !important;
      background-color: #262c31 !important;
      color: #d0dce8 !important;
      text-align: left;
      text-indent: 5px;
    }
    .el-menu-item:hover {
      color: #ffffff !important;
      background-color: #8ebaec !important;
    }
    /deep/.el-submenu .is-active {
      color: #ffffff !important;
      background-color: #2880e4 !important;
    }
    /deep/.el-submenu {
      .el-menu {
        background-color: #262c31 !important;
      }
    }
  }
}
</style>
<!-- css样式 end-->
