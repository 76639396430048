<!--
 * 头部组件
 * @author 滕威
 * @time 2019-12-05
 *
-->
<!-- VUE模板(html)代码 start -->
<template>
  <div class="header">
    <div class="left">
      <div class="logo_wrap">标书递交工具后台管理系统</div>
    </div>
    <div class="right">
      <div class="right-left">
        <i style="font-size: 20px;" class="el-icon-user-solid"></i>
        <span class="realname">欢迎您，{{ userInfo.realName }}</span>
        <p class="hover-realname">{{ userInfo.realName }}</p>
      </div>
      <div class="right-right" @click="fun_LoginOut">
        <i style="font-size: 20px;" class="el-icon-switch-button"></i>
        <span>退出登录</span>
      </div>
    </div>
  </div>
</template>
<!-- VUE模板(html)代码 end -->

<!-- JS代码 start -->
<script>
/*
 * 导入axios接口
 * 登录：login
 * @author 滕威
 */
import { fun_GetAction } from "@/api";
import { mapState } from "vuex";
/**
 * 导入加密组件
 * @author 滕威
 */
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["userInfo"])
  },
  /**
   * 自定义方法模块
   */
  methods: {
    /**
     * 退出登录方法
     * @author 滕威
     */
    fun_LoginOut() {
      // 询问框
      this.$confirm("您确定退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        // 调用登录接口
        fun_GetAction("/loginOut").then(res => {
          // 解构res.data
          const { result, msg } = res.data;
          // 判断接口参数result
          if (result) {
            // 清空sessionStorage
            sessionStorage.clear();
            // 跳转到登录页
            this.$router.push("/");
          } else {
            // 错误消息提示
            if (msg) this.$message.error(msg);
          }
        });
      });
    }
  }
};
</script>
<!-- JS代码 end -->

<!-- css样式 start-->
<style lang="scss" scoped>
.header {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-between;
  background: #2880e4;
  height: 100%;
  .left {
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo_wrap {
      padding-left: 45px;
      font-family: "Source Han Sans SC";
      font-size: 22px;
      font-weight: 500;
    }
    .btn {
      margin-left: 30px;
      padding: 0 3px;
      border: 1px solid #6569e3;
      border-radius: 3px;
      text-align: center;
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      color: #6569e3;
    }
  }
  .right {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: flex-end;
    padding-right: 15px;
    color: #ffffff;
    .right-icon {
      cursor: pointer;
      margin-right: 20px;
      display: flex;
      align-items: center;
      img {
        margin-right: 3px;
      }
    }
    .right-left {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 20px;
      > i {
        padding-right: 2px;
      }
      > span {
        display: inline-block;
        max-width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .hover-realname {
        opacity: 0;
        transition: opacity 0.8s;
        padding: 5px;
        color: #333333;
        background: #fff;
        position: absolute;
        top: 70%;
        width: 100%;
        z-index: 999;
        border-radius: 5px;
        line-height: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        transform-origin: center bottom;
        word-break: break-all;
      }
    }
    .right-right {
      display: flex;
      align-items: center;
      cursor: pointer;
      > i {
        padding-right: 2px;
      }
    }
    .right-left:hover .hover-realname {
      opacity: 1;
      transition: opacity 0.8s;
    }
  }
}
@media screen and (max-height: 620px) {
  .header {
    // min-width: 900px;
  }
}
</style>
<!-- css样式 end-->
