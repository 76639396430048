/**
 * @Description 导出axios拦截器方法
 * @author 滕威
 * @time 2019-12-02
 */
import axios from "axios";
import qs from "qs";
import { Message } from "element-ui";
import Router from "../router/router.js";

const service = axios.create({
  baseURL: "/dbgjs-server",
  timeout: 15000,
  withCredentials: true,
  paramsSerializer: params =>
    qs.stringify(params, {
      arrayFormat: "repeat"
    })
});
// 初始化token
let token = null;
// 初始化platformCode
let platformCode = null;
if (
  window.location.href.indexOf("?") !== -1 &&
  window.location.href.indexOf("&") !== -1
) {
  // 截取?后面的参数
  let url = window.location.href.split("?")[1];
  // 获取分割的数组
  let strList = url.split("=");
  // 判断分割的url是否来自客户端
  if (strList[0] === "token") {
    // 获取token
    token = strList[1].split("&")[0];
    // 获取platformCode
    platformCode = strList[2];
    // 存储token
    sessionStorage.setItem("token", token);
    // 存储platformCode
    sessionStorage.setItem("platformCode", platformCode);
  }
}
service.interceptors.request.use(config => {
  config.headers.Authorization = token;
  if (
    (config.method === "put" || config.method === "post") &&
    config.headers["Content-Type"] !== "application/json" &&
    config.headers["Content-Type"] !== "multipart/form-data"
  ) {
    config.data = qs.stringify(
      {
        ...config.data
      },
      {
        arrayFormat: "repeat"
      }
    );
    // config.data = qs.stringify(config.data, { arrayFormat: "repeat" });
  }
  // 请求头添加token
  if (sessionStorage.getItem("TB__TOKEN")) {
    config.headers.Authorization = JSON.parse(
      sessionStorage.getItem("TB__TOKEN")
    );
  }
  // 时间戳
  if (config.method === "get") {
    config.params = {
      ...config.params
    };
  }
  return config;
}),
  error => {
    Promise.reject(error);
  };

service.interceptors.response.use(
  response => {
    // 401未登录, 301非法访问, 404  都跳到登陆页
    return response;
  },
  error => {
    if (error.response && error.response.status) {
      const { status, data } = error.response;
      switch (status) {
        case 401:
          if (data && !data.result) {
            Message.error(data.msg);
            sessionStorage.removeItem("TB__TOKEN");
            Router.push({ path: "/" });
          }
          break;
        case 403:
          Router.push({ name: "exception403" });
          break;
        case 404:
          Router.push({ name: "exception404" });
          break;
        case 405:
          Message.error("请求异常！");
          break;
        case 500:
          if (data && !data.result) {
            Message.error(data.msg);
          }
          Router.push({ name: "exception500" });
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
