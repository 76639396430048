<template>
  <el-form :model="enInform" ref="enInform" :label-width="labelWidth">
    <el-row>
      <el-col :span="span">
        <el-form-item
          ref="formItem"
          v-for="(item, index) in formConfigList"
          :class="item.required ? '' : 'textLabel'"
          :key="index"
          :label="item.label"
          :prop="item.value"
          :error="errRules ? errRules[item.value] : ''"
          :rules="[item.rule ? item.rule : {}]"
          style="text-align: left;"
        >
          <!-- solt -->
          <template v-if="item.type === 'slot'">
            <slot :name="item.slotName" />
          </template>
          <!-- 文字输入框 -->
          <el-input
            v-model="enInform[item.value]"
            v-if="item.type === 'input'"
            :disabled="item.disabled"
            :clearable="item.clearable"
            :placeholder="`${item.placeholder ? item.placeholder : ''}`"
          ></el-input>
          <span v-if="item.inputSpan" style="margin-left: 8px;">{{
            item.inputSpan
          }}</span>
          <!-- 密码输入框 -->
          <el-input
            v-model="enInform[item.value]"
            :placeholder="`${item.placeholder ? item.placeholder : ''}`"
            v-if="item.type === 'password'"
            :clearable="item.clearable"
            :disabled="item.disabled"
            type="password"
          ></el-input>
          <!-- 数字输入框 -->
          <el-input
            v-model.number="enInform[item.value]"
            :placeholder="`${item.placeholder ? item.placeholder : ''}`"
            v-if="item.type === 'number'"
            :clearable="item.clearable"
            :disabled="item.disabled"
          ></el-input>
          <!-- 文本域 -->
          <el-input
            type="textarea"
            v-model="enInform[item.value]"
            v-if="item.type === 'textarea'"
            :placeholder="`${item.placeholder ? item.placeholder : ''}`"
            :clearable="item.clearable"
            :disabled="item.disabled"
            :maxlength="item.maxlength ? item.maxlength : 99999"
            :show-word-limit="item.showLimit ? true : false"
          ></el-input>
          <!-- 单选框 -->
          <el-radio-group
            v-model="enInform[item.value]"
            :disabled="item.disabled"
            v-if="item.type === 'radio'"
          >
            <el-radio
              v-for="(item, index) in item.option"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
          <!-- span -->
          <span style="color: red;" v-if="item.type === 'span'">{{
            item.value
          }}</span>
          <!-- 下拉框 -->
          <Co-select
            ref="coSelect"
            :codeSting="enInform[item.value]"
            :queryParams="item.queryParams ? item.queryParams : ''"
            :clearable="item.clearable"
            :disabled="item.disabled"
            :placeholder="`${item.placeholder ? item.placeholder : ''}`"
            v-if="item.type === 'select'"
            @change="fun_SelectChange($event, item.value)"
          />
          <!-- 树形下拉框 -->
          <Co-cascader
            :codeSting="enInform[item.value]"
            :sqlId="`${item.sqlId ? item.sqlId : ''}`"
            :params="item.params"
            :url="`${item.url ? item.url : ''}`"
            :clearable="item.clearable"
            :disabled="item.disabled"
            :optionProps="item.optionProps"
            :showAllLevels="item.showAllLevels"
            v-if="item.type === 'cascader'"
            @change="fun_SelectChange($event, item.value)"
          />
          <!-- 日期选择框 -->
          <el-date-picker
            v-if="item.type === 'date'"
            v-model="enInform[item.value]"
            :type="item.dateType"
            :picker-options="item.TimePickerOptions"
            :clearable="item.clearable"
            :disabled="item.disabled"
            :placeholder="`${item.placeholder ? item.placeholder : ''}`"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    // 表单数据
    enInform: {
      type: Object
    },
    // 相关字段
    formConfigList: {
      type: Array
    },
    // 自定义验证规则
    errRules: {
      type: Object
    },
    // label宽度
    labelWidth: {
      type: String,
      default: "120px"
    },
    // el-col span
    span: {
      type: Number
    }
  },
  methods: {
    // 下拉框change事件
    fun_SelectChange($event, value) {
      this.$emit("fun_GetSelectVal", $event.code, value);
    }
  }
};
</script>
