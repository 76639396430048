<template>
  <div class="collapse-form">
    <el-form :model="enInform" ref="enInform" :label-width="labelWidth">
      <el-row>
        <el-col>
          <el-collapse v-model="activeNames" style="margin:20px 0px;">
            <el-collapse-item
              v-for="item in formConfigList"
              :key="item.value"
              :title="item.title"
              :name="item.name"
            >
              <template slot="title">
                <i
                  :class="`header-icon el-icon-${item.icon}`"
                  style="margin-right: 2px;"
                ></i
                >{{ item.title }}
              </template>
              <el-form-item
                ref="formItem"
                v-for="(item, index) in item.formConfigList"
                :class="item.required ? '' : 'textLabel'"
                :key="index"
                :label="item.label"
                :prop="item.value"
                :error="errRules ? errRules[item.value] : ''"
                :rules="[item.rule ? item.rule : {}]"
                style="text-align: left;"
              >
                <!-- solt -->
                <template v-if="item.type === 'slot'">
                  <slot :name="item.slotName" />
                </template>
                <!-- 文字输入框 -->
                <el-input
                  v-model="enInform[item.value]"
                  v-if="item.type === 'input'"
                  :disabled="item.disabled"
                  :clearable="item.clearable"
                  :placeholder="`${item.placeholder ? item.placeholder : ''}`"
                ></el-input>
                <span v-if="item.inputSpan" style="margin-left: 8px;">{{
                  item.inputSpan
                }}</span>
                <!-- 密码输入框 -->
                <el-input
                  v-model="enInform[item.value]"
                  :placeholder="`${item.placeholder ? item.placeholder : ''}`"
                  v-if="item.type === 'password'"
                  :clearable="item.clearable"
                  :disabled="item.disabled"
                  type="password"
                ></el-input>
                <!-- 数字输入框 -->
                <el-input
                  v-model.number="enInform[item.value]"
                  :placeholder="`${item.placeholder ? item.placeholder : ''}`"
                  v-if="item.type === 'number'"
                  :clearable="item.clearable"
                  :disabled="item.disabled"
                ></el-input>
                <!-- 文本域 -->
                <el-input
                  type="textarea"
                  v-model="enInform[item.value]"
                  v-if="item.type === 'textarea'"
                  :placeholder="`${item.placeholder ? item.placeholder : ''}`"
                  :clearable="item.clearable"
                  :disabled="item.disabled"
                  :maxlength="item.maxlength ? item.maxlength : 99999"
                  :show-word-limit="item.showLimit ? true : false"
                ></el-input>
                <!-- 单选框 -->
                <el-radio-group
                  v-model="enInform[item.value]"
                  :disabled="item.disabled"
                  v-if="item.type === 'radio'"
                >
                  <el-radio
                    v-for="(item, index) in item.option"
                    :key="index"
                    :label="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
                <!-- span -->
                <span style="color: red;" v-if="item.type === 'span'">{{
                  item.value
                }}</span>
                <!-- 下拉框 -->
                <Co-select
                  :codeSting="enInform[item.value]"
                  :queryParams="item.queryParams ? item.queryParams : ''"
                  :clearable="item.clearable"
                  :disabled="item.disabled"
                  :placeholder="`${item.placeholder ? item.placeholder : ''}`"
                  v-if="item.type === 'select'"
                  @change="fun_SelectChange($event, item.value)"
                />
                <!-- 树形下拉框 -->
                <Co-cascader
                  :codeSting="enInform[item.value]"
                  :sqlId="`${item.sqlId ? item.sqlId : ''}`"
                  :params="item.params"
                  :url="`${item.url ? item.url : ''}`"
                  :clearable="item.clearable"
                  :disabled="item.disabled"
                  :optionProps="item.optionProps"
                  :showAllLevels="item.showAllLevels"
                  v-if="item.type === 'cascader'"
                  @change="fun_SelectChange($event, item.value)"
                />
                <!-- 日期选择框 -->
                <el-date-picker
                  v-if="item.type === 'date'"
                  v-model="enInform[item.value]"
                  :type="item.dateType"
                  :picker-options="item.TimePickerOptions"
                  :clearable="item.clearable"
                  :disabled="item.disabled"
                  :placeholder="`${item.placeholder ? item.placeholder : ''}`"
                />
              </el-form-item>
            </el-collapse-item>
            <!-- 按钮组 -->
            <div class="tjBtn">
              <slot :name="btnSlotName"></slot>
              <el-button type="info" @click="fun_Back">返回</el-button>
            </div>
          </el-collapse>
        </el-col>
      </el-row>
    </el-form>
    <!-- 审核按钮组 -->
    <!-- <div class="tjBtn" v-if="!isExaminationNo">
      <el-form>
        <el-form-item label>
          <el-button
            v-if="$route.params.type === 'audit'"
            type="primary"
            @click="fun_Pass()"
            >通过</el-button
          >
          <el-button
            v-if="$route.params.type === 'audit'"
            type="danger"
            @click="fun_SendBack('enInform')"
            >退回</el-button
          >
          <el-button @click="fun_Back">返回</el-button>
        </el-form-item>
      </el-form>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: this.formConfigList.map(item => {
        return item.name;
      }) // 默认展开全部面板
    };
  },
  props: {
    // 表单数据
    enInform: {
      type: Object
    },
    // 相关字段
    formConfigList: {
      type: Array
    },
    // 自定义验证规则
    errRules: {
      type: Object
    },
    // label宽度
    labelWidth: {
      type: String,
      default: "120px"
    },
    // 右下角button插槽
    btnSlotName: {
      type: String,
      default: null
    }
    // refObj: {
    //   type: Object
    // },
    // 提交按钮状态
    // subBtnStatus: {
    //   type: String
    // },
    // // 提交按钮状态
    // changeBtnStatus: {
    //   type: Boolean,
    //   default: false
    // },
    // // 右下角按钮组状态
    // isExaminationNo: {
    //   type: Boolean,
    //   default: false
    // }
    // slotName: {
    //   type: String,
    //   default: null
    // }
  },
  // watch: {
  //   enInform: {
  //     handler: function() {
  //       // 将form实例返回到父级
  //       this.$emit("update:refObj", this.$refs.enInform);
  //     },
  //     deep: true // 深度监听
  //   }
  // },
  mounted() {
    // 将form实例返回到父级
    // this.$emit("update:refObj", this.$refs.enInform);
  },
  methods: {
    // 提交按钮
    // fun_Submission(formName) {
    //   this.$emit("fun_Submission", formName);
    //   this.$refs[formName].validate(valid => {
    //     if (valid) {
    //       this.$emit("fun_Submission", formName);
    //     } else {
    //       // 校验不通过，返回false，页面给出前端提示
    //       this.$message.error("请按提示修改！");
    //       return false;
    //     }
    //   });
    // },
    // // 保存按钮
    // fun_Conserve(formName) {
    //   this.$refs[formName].validate(valid => {
    //     if (valid) {
    //       this.$emit("fun_ConSon", formName);
    //     } else {
    //       this.$message.error("请按提示修改！");
    //       return false;
    //     }
    //   });
    // },
    // 返回按钮
    fun_Back() {
      this.$emit("fun_Back");
    },
    // // 通过按钮
    // fun_Pass(formName) {
    //   this.$emit("fun_Pass", formName);
    // },
    // // 退回按钮
    // fun_SendBack(formName) {
    //   this.$emit("fun_SendBack", formName);
    // },
    // 下拉框change事件
    fun_SelectChange($event, value) {
      this.$emit("fun_GetSelectVal", $event.code, value);
    }
  }
};
</script>
