<template>
  <div class="layout-list">
    <el-form
      :model="form"
      ref="form"
      :label-width="labelWidth"
      :inline="true"
      v-if="formSearchList && formSearchList.length > 0"
    >
      <el-row>
        <el-col>
          <el-form-item
            ref="formItem"
            v-for="(item, index) in formSearchList"
            :class="item.required ? '' : 'textLabel'"
            :key="index"
            :label="item.label"
            :prop="item.value"
            style="text-align: left;"
          >
            <!-- solt -->
            <template v-if="item.type === 'slot'">
              <slot :name="item.slotName" />
            </template>
            <!-- 输入框 -->
            <el-input
              v-model="form[item.value]"
              v-if="item.type === 'input'"
              :disabled="item.disabled"
              :clearable="item.clearable"
              :placeholder="`${item.placeholder ? item.placeholder : ''}`"
            ></el-input>
            <!-- 密码输入框 -->
            <el-input
              v-model="form[item.value]"
              v-if="item.type === 'password'"
              type="password"
              :disabled="item.disabled"
              :clearable="item.clearable"
              :placeholder="`${item.placeholder ? item.placeholder : ''}`"
            ></el-input>
            <span v-if="item.inputSpan" style="margin-left: 8px;">{{
              item.inputSpan
            }}</span>
            <!-- 下拉框 -->
            <Co-select
              :codeSting="form[item.value]"
              :queryParams="item.queryParams ? item.queryParams : ''"
              :clearable="item.clearable ? item.clearable : true"
              :disabled="item.disabled"
              :placeholder="`${item.placeholder ? item.placeholder : ''}`"
              :multipleValue="item.multipleValue ? item.multipleValue : false"
              v-if="item.type === 'select'"
              @change="fun_SelectChange($event, item.value)"
            />
            <!-- 日期选择框 -->
            <el-date-picker
              v-if="item.type === 'date'"
              v-model="form[item.value]"
              :type="item.dateType"
              :picker-options="item.TimePickerOptions"
              :clearable="item.clearable"
              :disabled="item.disabled"
              :start-placeholder="`${item.dateType ? '开始日期' : ''}`"
              :end-placeholder="`${item.dateType ? '结束日期' : ''}`"
              :placeholder="`${item.placeholder ? item.placeholder : ''}`"
            />
          </el-form-item>
          <!-- 查询 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="fun_Search"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="tips" v-if="tipSlotName">
      <slot :name="tipSlotName" />
    </div>
    <div class="table-header" v-if="btnList && btnList.length > 0">
      <el-button
        v-for="(e, i) of btnList"
        :key="i"
        :type="e.type"
        :icon="'el-icon-' + e.icon"
        @click="fun_click(e.name)"
        >{{ e.name }}</el-button
      >
    </div>
    <el-table
      v-if="tableColumns && tableColumns.length > 0"
      :data="tableData"
      ref="multipleTable"
      :border="border"
      fit
      v-loading="loading"
      element-loading-text="加载中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      style="width: 100%;"
      :header-cell-style="{ background: '#f5f5f5' }"
      :max-height="maxHeight"
      @selection-change="fun_HandleSelectionChange"
      default-expand-all
      row-key="id"
      default-expand-all:false
      :tree-props="treeProps"
      @sort-change="fun_ChangeTableSort"
      :row-class-name="tableRowClassName"
      @row-click="fun_RowClick"
      id="qwesd"
    >
      <el-table-column
        fixed="left"
        type="index"
        align="center"
        width="50"
        :label="numLabel ? numLabel : ''"
        v-if="sNumber"
      ></el-table-column>
      <el-table-column
        fixed="left"
        type="selection"
        align="center"
        width="50"
        v-if="selection"
      ></el-table-column>
      <el-table-column
        v-for="(column, index) in tableColumns"
        :align="column.align ? column.align : 'center'"
        :prop="column.prop"
        :key="index"
        :label="column.label"
        :width="column.width"
        :show-overflow-tooltip="!column.showTool ? true : false"
        :sortable="column.sorting ? column.sorting : false"
        :fixed="column.fixed ? column.fixed : false"
      >
        <template slot-scope="scope">
          <!-- 点击查看 -->
          <span
            :style="{ color: column.textColor ? column.textColor : '#6569E3' }"
            style="cursor: pointer;"
            @click="fun_LookDetails(scope.row)"
            v-if="column.isShow"
            >{{ scope.row[column.prop] }}</span
          >
          <!-- 状态 -->
          <span
            v-else-if="column.state"
            :style="{
              color:
                scope.row[column.prop] === column.state[0]
                  ? 'green'
                  : scope.row[column.prop] === column.state[1]
                  ? 'gray'
                  : scope.row[column.prop] === column.state[2]
                  ? 'red'
                  : 'black'
            }"
            >{{ scope.row[column.prop] }}</span
          >
          <!-- 时间展示 -->
          <span v-else-if="column.insertDate && scope.row[column.prop]">
            {{ scope.row[column.prop] | formatDate }}
          </span>
          <!-- 普通字段 -->
          <span v-else>
            {{ scope.row[column.prop] }}
          </span>
          <!-- 具名插槽传递参数 -->
          <slot
            :name="column.slotName"
            v-if="column.slotName"
            :data="scope.row"
          ></slot>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="reqPageData.current"
      class="layout-pagein"
      @size-change="fun_HandleSizeChange"
      @current-change="fun_HandleCurrentChange"
      :current-page="reqPageData.current"
      :page-sizes="pagesizes"
      :page-size="reqPageData.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
// 引入时间格式转换方法
import { formatDate } from "@/utils/validate.js";
export default {
  // 初始化
  data: () => {
    return {
      count: 0 // 根据数值清空勾选
    };
  },
  // 过滤属性
  filters: {
    formatDate(time) {
      let date = new Date(time);
      // 返回时间格式
      return formatDate(date, "yyyy-MM-dd  hh:mm:ss");
    }
  },
  // 接收来自父组件的数据
  props: {
    pagesizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 50, 100];
      }
    },
    maxHeight: {
      type: String,
      default: "441px"
    },
    labelWidth: {
      type: String,
      default: "100px"
    },
    formSearchList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 表格列数据
    tableColumns: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    // 表格嵌套tree
    treeProps: {
      type: Object,
      default: () => {}
    },
    // 显隐值
    isShow: {
      type: Boolean,
      default: false,
      required: false
    },
    // loading状态
    loading: {
      type: Boolean,
      required: false
    },
    // 表格border
    border: {
      type: Boolean,
      default: true
    },
    // 勾选方法
    fun_HandleSelectionChange: {
      type: Function,
      required: false,
      default: () => {}
    },
    // 把每一行的索引放进row
    tableRowClassName: {
      type: Function,
      required: false,
      default: () => {}
    },
    // 点击某一行执行
    fun_RowClick: {
      type: Function,
      required: false,
      default: () => {}
    },
    // 排序方法
    fun_ChangeTableSort: {
      type: Function,
      required: false,
      default: () => {}
    },
    // 按钮
    btnList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 是否序号文字
    numLabel: {
      type: String,
      default: null
    },
    // 是否显示序号
    sNumber: {
      type: Boolean,
      default: true
    },
    // 是否显示列表勾选
    selection: {
      type: Boolean,
      default: true
    },
    // 表格插槽
    slotName: {
      default: null
    },
    // 分页自定义校验对象
    reqPageData: {
      type: Object, // 类型
      // 默认值
      default: () => {
        return {};
      }
    },
    // 分页总条数
    total: {
      type: Number,
      default: 0
    },
    // 说明文字插槽
    tipSlotName: {
      type: String
    }
  },
  // 监听事件
  watch: {
    // 根据数值清空勾选
    count(newVal) {
      // 如果值大于0，则清空勾选数组
      if (newVal > 0) this.$refs.multipleTable.clearSelection();
    }
  },
  // 自定义方法
  methods: {
    /**
     * @description 下拉框change事件
     * @author 滕威
     */
    fun_SelectChange($event, value) {
      this.$emit("fun_GetSelectVal", $event.code, value);
    },
    /**
     * @description 下拉框change事件
     * @author 滕威
     */
    fun_Search() {
      this.$emit("fun_Search");
    },
    /**
     * @description 点击单元格名称
     * @author 滕威
     */
    fun_LookDetails(val, name) {
      // 子传父
      this.$emit("fun_LookDetails", { val, name });
    },
    /**
     * @description 点击按钮
     * @param {e} 按钮名称
     * @author 滕威
     */
    fun_click(e) {
      this.$emit("fun_click", e);
    },
    /**
     * 改变每页条数执行的函数
     * @params val 每页条数
     * @author 滕威
     */

    fun_HandleSizeChange(val) {
      // 子组件向父组件传递方法，使用$emit("方法名", 需要传递的值)
      this.$emit("fun_LimitChange", val);
    },
    /**
     * 改变页码执行的函数
     * @params val 页码数
     * @author 滕威
     */

    fun_HandleCurrentChange(val) {
      // 子组件向父组件传递方法，使用$emit("方法名", 需要传递的值)
      this.$emit("fun_PageChange", val);
    }
  }
};
</script>
