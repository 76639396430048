import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 流程管理标签页切换name
    proManActive: "1",
    // 路由
    asyncRouters: [],
    // token
    TB__TOKEN: null,
    userInfo: {}
  },
  mutations: {
    // 存储流程管理标签页name
    SET_ACTIVE(state, data) {
      state.proManActive = data;
    },
    // 路由存储
    SET_ROUTER(state, data) {
      state.asyncRouters = data;
      sessionStorage.setItem("asyncRouters", JSON.stringify(data));
    },
    // token存储
    SET_TOKEN(state, data) {
      state.TB__TOKEN = data;
      sessionStorage.setItem("TB__TOKEN", JSON.stringify(data));
    },
    // 储存用户信息
    SET_USERINFO(state, data) {
      state.userInfo = data;
      sessionStorage.setItem("userInfo", JSON.stringify(data));
    }
  },
  actions: {},
  modules: {}
});
