/**
 * @Description 全局导出组件
 * @author 滕威
 * @time 2019-12-02
 */
import select from "./common/select";
import list from "./common/list";
import editor from "./common/editor";
import collapseForm from "./common/collapseForm";
import form from "./common/form";

function plugin(Vue) {
  if (plugin.installed) return;
  Vue.component("Co-select", select);
  Vue.component("Co-list", list);
  Vue.component("Co-editor", editor);
  Vue.component("Co-collapseForm", collapseForm);
  Vue.component("Co-form", form);
}

export default plugin;
