<!--
 * 主页架构组件
 * @author 滕威
 * @time 2021-01-08
 *
-->
<!-- VUE模板(html)代码 start -->
<template>
  <div class="home">
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <el-container
        style="
          width: 100%;
          display: flex;
        "
      >
        <el-aside width="200px">
          <Menu />
        </el-aside>
        <el-main>
          <!-- <Workbench v-if="$route.path === '/home' && roleType === '1'" />
          <Welcome v-if="$route.path === '/home' && roleType !== '1'" /> -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<!-- VUE模板(html)代码 end -->

<!-- JS代码 start -->
<script>
import Menu from "./components/menu";
import Header from "./components/header";
export default {
  name: "Home",
  components: {
    Menu,
    Header
  },
  data: () => {
    return {};
  },
  methods: {}
};
</script>
<!-- JS代码 end -->

<!-- css样式 start-->
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: hsla(220, 4%, 58%, 0.3);
}
.home {
  height: 100%;
}
.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 70px;
  height: 70px !important;
  padding: 0;
  // min-width: 1200px;
}
// @media screen and (max-height: 620px) {
//   .el-header {
//     min-width: 900px;
//   }
// }

.el-container {
  height: 100%;
}
// .el-header + .el-container {
//   overflow-y: auto;
// }

.el-aside {
  background-color: #3c4348;
  text-align: center;
  display: block;
  overflow: auto;
  height: 100%;
}

.el-main {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  // min-width: 1200px;
  // overflow: auto;
  // display: flex;
  // display: -webkit-flex;
  // display: -ms-flexbox;
  // flex-direction: column;
  // -webkit-box-orient: vertical;
  // -ms-flex-direction: column;
  // -webkit-flex-direction: column;
}
@media screen and (max-height: 620px) {
  .el-main {
    // min-width: 900px;
  }
}
</style>
<!-- css样式 end-->
