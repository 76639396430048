import { Message, MessageBox } from "element-ui";

/**
 * @Description 将时间戳转为日期格式
 * @param {日期} date
 * @param {格式} fmt
 * @author 滕威
 */
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

/**
 * 返回base64编码
 * @params url 图片下载地址
 * @author 滕威
 */
export const fun_GetUrlBase64 = url => {
  return new Promise(resolve => {
    // 创建canvas
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    // 创建img元素
    let img = new Image();
    // img.crossOrigin = 'Anonymous' //允许跨域
    // 获取img url
    img.src = url;
    // 初始化img属性
    img.onload = function() {
      canvas.height = 300;
      canvas.width = 300;
      ctx.drawImage(img, 0, 0, 300, 300);
      let dataURL = canvas.toDataURL("image/png");
      canvas = null;
      resolve(dataURL);
    };
  });
};

/**
 * 获取两个日期间的所以日期
 */
export const getMonthBetween = (start, end) => {
  var d1 = start,
    d2 = end;
  var result = [];
  var y1 = parseInt(d1.substr(0, 4));
  var m1 = parseInt(d1.substr(5, 2)) - 1;
  var y2 = parseInt(d2.substr(0, 4));
  var m2 = parseInt(d2.substr(5, 2)) - 1;
  do {
    var d = new Date(y1, m1++, 1);
    var y = d.getFullYear();
    var m = d.getMonth() + 1;
    var s = y + (m < 10 ? "0" : "") + m;
    result.push(s);
  } while (d < new Date(y2, m2, 1));
  return result;
};

/**
 * 下载文件公共方法
 * @params res 下载文件接口返回的流
 * @params pictureName 文件名称
 * @author 滕威
 */
export const fun_DownloadFile = (res, pictureName) => {
  const data = new Blob([res.data]);
  const reader = new FileReader();
  // 读取文件
  reader.readAsText(data);
  // 触发onload事件
  reader.onload = () => {
    // json格式数据转换成对象
    if (reader.result.indexOf("result") > -1) {
      const result = JSON.parse(reader.result);
      if (result.result == false) {
        Message.error(result.msg);
      }
    } else {
      if (window.navigator.msSaveBlob) {
        // IE以及IE内核的浏览器
        // window.navigator.msSaveBlob(data, pictureName);
        window.navigator.msSaveOrOpenBlob(data, pictureName);
      } else {
        let link = document.createElement("a");
        // 创建URL对象
        link.href = URL.createObjectURL(data);
        // 放入body元素中
        document.body.appendChild(link);
        // 获取图片名字
        link.download = pictureName;
        // 执行a标签点击事件
        link.click();
        // 释放URL对象
        URL.revokeObjectURL(link.href);
        // 在body中移除元素
        document.body.removeChild(link);
      }
    }
  };
};

/**
 * 下载文件公共方法（无错误判断）
 * @params res 下载文件接口返回的流
 * @params pictureName 文件名称
 * @author cys
 */
export const fun_DownloadFileNot = (res, pictureName) => {
  const data = new Blob([res.data]);
  const reader = new FileReader();
  // 读取文件
  reader.readAsText(data);
  // 触发onload事件
  reader.onload = () => {
    if (window.navigator.msSaveBlob) {
      // IE以及IE内核的浏览器
      // window.navigator.msSaveBlob(data, pictureName);
      window.navigator.msSaveOrOpenBlob(data, pictureName);
    } else {
      let link = document.createElement("a");
      // 创建URL对象
      link.href = URL.createObjectURL(data);
      // 放入body元素中
      document.body.appendChild(link);
      // 获取图片名字
      link.download = pictureName;
      // 执行a标签点击事件
      link.click();
      // 释放URL对象
      URL.revokeObjectURL(link.href);
      // 在body中移除元素
      document.body.removeChild(link);
    }
  };
};

/**
 * alert提示消息公共方法
 * @params title 提示内容
 * @author 滕威
 */
export const fun_CommonAlert = title => {
  MessageBox.alert(title, "提示", {
    type: "warning"
  }).then(() => {});
};

/**
 * 数组属性值拆分用;隔开公共方法
 * @description 主要用于批量删除时组合id
 * @params parameter 数组参数
 * @params state 判断是否可以修改删除的字段
 * @author 滕威
 */
export const fun_GetKeys = rows => {
  if (rows.length > 1) {
    let keys = [];
    rows.map(e => {
      keys = [...keys, e.id];
    });
    return keys.join(",");
  } else {
    return rows[0].id;
  }
};

/**
 * @description 审核状态转译
 * @param 审核状态value
 * @author 滕威
 */
export const fun_CheckState = checkState => {
  switch (checkState) {
    case "0":
      return "审核中";
    case "1":
      return "审核通过";
    case "2":
      return "审核不通过";
    case "3":
      return "待提交";
  }
};

/**
 * @description 用户状态转译
 * @param 用户状态value
 * @author 滕威
 */
export const fun_UserState = userState => {
  switch (userState) {
    case "0":
      return "废止";
    case "1":
      return "启用";
  }
};

/**
 * @description 角色类型转译
 * @param 角色类型value
 * @author 滕威
 */
export const fun_UserRole = userRole => {
  switch (userRole) {
    case "204b4c78-9a74-449d-aeb2-3b287c4398d1":
      return "工作人员";
    case "6c4c0dee-0490-4e8b-91b9-ea01063eabc4":
      return "系统管理员";
  }
};

/**
 * @description 是否固定角色转译
 * @param 是否固定角色value
 * @author 滕威
 */
export const fun_FixedAuthority = fixedAuthority => {
  switch (fixedAuthority) {
    case "0":
      return "否";
    case "1":
      return "是";
  }
};

/**
 * @description 会议类型转译
 * @param 会议类型value
 */
export const fun_MeetingType = TypeNumber => {
  switch (TypeNumber) {
    case "1":
      return "开标会";
    case "2":
      return "评标会";
  }
};
